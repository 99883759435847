import { cloneDeep } from 'lodash';
import makeReducer from 'lib/makeReducer';

const getInitialResourcesModalState = () => ({
  assets: [],
  sources: [],
  sortBy: 'id',
  sortOrder: 'ASC',
  search: '',
  isModalOpen: false,
  errors: [],
  page: 1,
  pageSize: 10,
  assetsCount: 0,
  sourcesCount: 0,
  tab: 'assets',
  isDirty: false,
});

const INITIAL_BULK_PARAMS = {
  validity: {
    value: '',
    errors: [],
  },
  validityRange: {
    value: null,
    errors: [],
  },
  aqlLevel: {
    value: null,
    errors: [],
  },
  aqlMajor: {
    value: null,
    errors: [],
  },
  aqlMinor: {
    value: null,
    errors: [],
  },
  aqlFunctional: {
    value: null,
    errors: [],
  },
  criticalDefect: {
    value: 0,
    errors: [],
  },
  criticalDefectRule: {
    value: 'Exact',
    errors: [],
  },
  type: {
    value: null,
    errors: [],
  },
};

const INITIAL_FORM_STATE = {
  overridePlans: { value: false, errors: [] },
  overrideUserPlans: { value: false, errors: [] },
  bulkParams: [{ ...INITIAL_BULK_PARAMS }],
  typeOptions: [],
  availableTypeOptions: [],
  planOptions: false,
  linkedResources: {
    assets: {
      selected: [],
      selectedPages: [],
      isAllSelected: false,
      totalSelected: 0,
    },
    sources: {
      selected: [],
      selectedPages: [],
      isAllSelected: false,
      totalSelected: 0,
    },
    errors: [],
  },
  filters: {
    assetStatus: [],
    companyAttribute: [],
    customAttribute: [],
    assetType: [],
    sourceTag: [],
    sourceType: [],
    sourceCountry: [],
    assetInspectionType: [],
    sourceInspectionType: [],
    inspectionType: [],
    sourceMissingPlans: false,
    assetMissingPlans: false,
  },
  filterOptions: {
    assetStatus: [],
    companyAttribute: [],
    customAttribute: [],
    assetType: [],
    sourceTag: [],
    sourceType: [],
    sourceCountry: [],
    assetInspectionType: [],
    sourceInspectionType: [],
  },
  linkableResources: getInitialResourcesModalState(),
  loading: false,
  autoBuildProgress: null,
  isSubmissionFinished: false,
  isDirty: false,
};

export const AUTO_BUILD_PLAN_FORM_ACTIONS = {
  APP_LOADS_TYPES: 'APP_LOADS_TYPES',
  APP_LOADS_OPTIONS: 'APP_LOADS_OPTIONS',
  APP_LOADS_FILTER_OPTIONS: 'APP_LOADS_FILTER_OPTIONS',
  APP_LOADS_AUTO_BUILD_PROGRESS: 'APP_LOADS_AUTO_BUILD_PROGRESS',
  APP_SUBMITS_SUCCESSFULLY: 'APP_SUBMITS_SUCCESSFULLY',
  APP_FINISHES_SUBMISSION: 'APP_FINISHES_SUBMISSION',
  RESET_STATE: 'RESET_STATE',

  USER_CHANGES_INPUT: 'USER_CHANGES_INPUT',
  USER_SUBMITS_FORM: 'USER_SUBMITS_FORM',
  USER_ADDS_NEW_BULK_PARAM: 'USER_ADDS_NEW_BULK_PARAM',
  USER_SETS_BULK_PARAM: 'USER_SETS_BULK_PARAM',
  USER_SETS_FILTER: 'USER_SETS_FILTER',
  USER_SETS_BOOL_FILTER: 'USER_SETS_BOOL_FILTER',
  USER_REMOVES_BULK_PARAM: 'USER_REMOVES_BULK_PARAM',
  APP_LOADS_RESOURCES: 'APP_LOADS_RESOURCES',
  USER_OPENS_RESOURCES_MODAL: 'USER_OPENS_RESOURCES_MODAL',
  USER_CANCELS_RESOURCES_MODAL: 'USER_CANCELS_RESOURCES_MODAL',
  USER_SELECTS_RESOURCES: 'USER_SELECTS_RESOURCES',
  USER_SEARCHES_RESOURCES: 'USER_SEARCHES_RESOURCES',
  USER_SORTS_RESOURCES: 'USER_SORTS_RESOURCES',
  USER_CHANGES_RESOURCES_PAGE: 'USER_CHANGES_RESOURCES_PAGE',
  USER_CHANGES_RESOURCES_MODAL_TAB: 'USER_CHANGES_RESOURCES_MODAL_TAB',
  APP_LOADS_LINKABLE_RESOURCES: 'APP_LOADS_LINKABLE_RESOURCES',
};

const setInputValue = (key, state, payload) => {
  return {
    ...state,
    [key]: {
      ...state[key],
      value: payload,
      errors: [],
    },
    isDirty: true,
  };
};

const AUTO_BUILD_PLAN_REDUCER_CONFIG = {
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_CHANGES_INPUT]: (state, action) => {
    return setInputValue(action.key, state, action.payload);
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.RESET_STATE]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_TYPES]: (state, action) => {
    return {
      ...state,
      typeOptions: action.payload,
      availableTypeOptions: action.payload,
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_OPTIONS]: (state, action) => {
    return { ...state, ...action.payload, loading: false };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_AUTO_BUILD_PROGRESS]: (
    state,
    action,
  ) => {
    return { ...state, autoBuildProgress: action.payload };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.APP_SUBMITS_SUCCESSFULLY]: () => {
    return {
      ...INITIAL_FORM_STATE,
      loading: false,
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.APP_FINISHES_SUBMISSION]: (state) => {
    return {
      ...state,
      loading: false,
      isSubmissionFinished: true,
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_OPENS_RESOURCES_MODAL]: (state) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        isModalOpen: true,
      },
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SEARCHES_RESOURCES]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        search: action.payload,
        page: 1,
      },
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SORTS_RESOURCES]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        ...action.payload,
      },
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_CHANGES_RESOURCES_PAGE]: (
    state,
    action,
  ) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        page: action.payload,
      },
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_CANCELS_RESOURCES_MODAL]: (state) => {
    return {
      ...state,
      linkableResources: getInitialResourcesModalState(),
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_CHANGES_RESOURCES_MODAL_TAB]: (
    state,
    action,
  ) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        search: '',
        sortBy: 'id',
        sortOrder: 'ASC',
        tab: action.payload,
        page: 1,
      },
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SELECTS_RESOURCES]: (state, action) => {
    return {
      ...state,
      linkedResources: { ...action.payload, errors: [] },
      linkableResources: getInitialResourcesModalState(),
      isDirty: true,
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_RESOURCES]: (state, action) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        ...action.payload,
      },
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SETS_BULK_PARAM]: (state, action) => {
    const { index, key, value } = action.payload;
    const newParams = cloneDeep(state.bulkParams);
    newParams[index][key] = { value, errors: [] };
    const availableTypes =
      key === 'type'
        ? (state.typeOptions || []).filter(
          (item) =>
            !newParams.some(
              (param) => param?.type?.value?.value === item.value,
            ),
        )
        : state.availableTypeOptions;

    return {
      ...state,
      bulkParams: newParams,
      availableTypeOptions: availableTypes,
      isDirty: true,
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_ADDS_NEW_BULK_PARAM]: (state) => {
    return {
      ...state,
      bulkParams: [...state.bulkParams, { ...INITIAL_BULK_PARAMS }],
      isDirty: true,
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SETS_FILTER]: (state, action) => {
    const { key, payload } = action;

    if (payload.value === 'all') {
      return {
        ...state,
        filters: { ...state.filters, [key]: [] },
      };
    }

    const index = state.filters[key].findIndex(
      (f) => f.value === payload.value,
    );

    if (index === -1) {
      return {
        ...state,
        filters: {
          ...state.filters,
          [key]: [...state.filters[key], payload],
        },
      };
    }

    const newFilter = [...state.filters[key]];
    newFilter.splice(index, 1);

    return {
      ...state,
      filters: { ...state.filters, [key]: newFilter },
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SETS_BOOL_FILTER]: (state, action) => {
    const { key, payload } = action;
    return {
      ...state,
      filters: { ...state.filters, [key]: payload },
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_REMOVES_BULK_PARAM]: (state, action) => {
    const newParams = cloneDeep(state.bulkParams);
    newParams.splice(action.payload, 1);
    const availableTypes = (state.typeOptions || []).filter(
      (item) =>
        !newParams.some((param) => param?.type?.value?.value === item.value),
    );

    return {
      ...state,
      bulkParams: newParams,
      availableTypeOptions: availableTypes,
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.USER_SUBMITS_FORM]: (state) => {
    return {
      ...state,
      loading: true,
      isDirty: false,
    };
  },
  [AUTO_BUILD_PLAN_FORM_ACTIONS.APP_LOADS_LINKABLE_RESOURCES]: (
    state,
    action,
  ) => {
    return {
      ...state,
      linkableResources: {
        ...state.linkableResources,
        list: action.payload,
      },
    };
  },
};

export const { reducer, initialState } = makeReducer(
  AUTO_BUILD_PLAN_REDUCER_CONFIG,
  INITIAL_FORM_STATE,
);
