
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import inspectionPlansService from 'modules/inspection-plans/inspectionPlansService';
import './InspectionPlansBulkResultsPage.scss';
import InspectionPlansBulkResultsPagePanel from './InspectionPlansBulkResultsPagePanel';

const InspectionPlansBulkResultsPage = () => {
  const [ipbJobStatusData, setIpbJobStatusData] = useState([]);

  useEffect(() => {
    inspectionPlansService
      .getAutoBuildProgress()
      .then((res) => {
        if (res?.response) {
          setIpbJobStatusData(res.response);
        } else {
          console.error('Empty IPB status response object', res.response);
          setIpbJobStatusData([]);
        }
      })
      .catch((e) => {
        console.error('Error while retrieving IPB statsu response objcet', e);
        setIpbJobStatusData([]);
      }
      );
  }, []);

  return (
    <div className='inspection-plans-bulk-results-page container-m'>
      <div className='row'>
        <h2>Inspection plans</h2>
      </div>
      {ipbJobStatusData?.length ? (
        <>
          {ipbJobStatusData?.map((item, i) => (

            <div key={i} className={
              classNames('ipb-bulk-results-item',
                {
                  "is-single": ipbJobStatusData.length === 1
                    && [null, undefined].includes(item?.bulkConfigIndex)
                })}>
              <h3>Batch Configuration {(item?.bulkConfigIndex ?? 0) + 1}</h3>
              <InspectionPlansBulkResultsPagePanel ipbJobStatusDataItem={item} />
            </div>
          ))}
        </>
      ) : (
        <p className='empty-plan-list'>No results data available.</p>
      )}
    </div>
  );
};

export default InspectionPlansBulkResultsPage;
