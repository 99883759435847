import classNames from 'classnames';

import AddButton from 'lib/components/add-button/AddButton';
import Button from 'lib/components/button/Button';
import Checkbox from 'lib/components/checkbox/Checkbox';
import DeleteButton from 'lib/components/delete-button/DeleteButton';
import ErrorBag from 'lib/components/error-bag/ErrorBag';
import Input from 'lib/components/input/Input';
import LinkableResourcesModal from 'lib/components/linkable-resources-modal/LinkableResourcesModal';
import ProtectedComponent from 'lib/components/protected-component/ProtectedComponent';
import Select from 'lib/components/select/Select';
import StatusPill, {
  STATUS_PILL_VARIANT,
} from 'lib/components/status-pill/StatusPill';
import Switch from 'lib/components/switch/Switch';
import { OVERRIDE_PLANS_BULK_CREATION } from 'modules/inspection-plans/inspectionPlansPermissions';
import useAutoBuildPlanForm from 'modules/inspection-plans/useAutoBuildPlanForm';
import BulkResultsHeader from '../bulk-results-header/BulkResultsHeader';

import { ReactComponent as NavigationOutline } from 'assets/images/navigation-outline.svg';
import { ReactComponent as PackageOutline } from 'assets/images/package-outline.svg';

import StatusProgressPanel from 'lib/components/csv-import/StatusProgressPanel';
import './AutoBuildPlanForm.scss';

const renderformLabel = (label, index) => (index > 0 ? undefined : label);

const getStatusTotals = (resArray) => {
  const output = {
    success: null,
    failed: null,
    skipped: null,
  };

  resArray?.forEach(v => {
    if (Number.isFinite(v?.successCount))
      output.success = output.success === null ? v.successCount : (output.success + v.successCount);
    if (Number.isFinite(v?.failedCount))
      output.failed = output.failed === null ? v.failedCount : (output.failed + v.failedCount);
    if (Number.isFinite(v?.skippedCount))
      output.skipped = output.skipped === null ? v.skippedCount : (output.skipped + v.skippedCount);
  });

  return output;
}

// eslint-disable-next-line max-lines-per-function
const AutoBuildPlanForm = (props) => {
  const vm = useAutoBuildPlanForm(props);

  const isAutoBuildActive = vm.state.autoBuildProgress?.status === 'active';
  const hasResults = !!(!isAutoBuildActive && vm.state.autoBuildProgress?.response?.length);

  const assetFilters = (
    <>
      {/* <div className='filters-row'> */}
      <div className='filter'>
        <label>Status:</label>
        <Select
          className='filter-select'
          options={vm.getFilterOptions('assetStatus')}
          value={vm.getFilterValue('assetStatus')}
          onChange={(option, action) =>
            vm.setFilter('assetStatus', option, action)
          }
          isOptionSelected={(option) => {
            return vm.state.filters.assetStatus.some(
              (f) => f.value === option.value,
            );
          }}
          isSearchable={true}
          menuPlacement='auto'
        />
      </div>
      <div className='filter'>
        <label>Company Attributes:</label>
        <Select
          className='filter-select'
          options={vm.getFilterOptions('companyAttribute')}
          value={vm.getFilterValue('companyAttribute')}
          onChange={(option, action) =>
            vm.setFilter('companyAttribute', option, action)
          }
          isOptionSelected={(option) => {
            return vm.state.filters.companyAttribute.some(
              (f) => f.value === option.value,
            );
          }}
          isSearchable={true}
          menuPlacement='auto'
        />
      </div>
      <div className='filter'>
        <label>Custom Attributes:</label>
        <Select
          className='filter-select'
          options={vm.getFilterOptions('customAttribute')}
          value={vm.getFilterValue('customAttribute')}
          onChange={(option) => vm.setFilter('customAttribute', option)}
          isOptionSelected={(option) => {
            return vm.state.filters.customAttribute.some(
              (f) => f.value === option.value,
            );
          }}
          isSearchable={true}
          menuPlacement='auto'
        />
      </div>
      <div className='filter'>
        <label>Asset Type:</label>
        <Select
          className='filter-select'
          options={vm.getFilterOptions('assetType')}
          value={vm.getFilterValue('assetType')}
          onChange={(option) => vm.setFilter('assetType', option)}
          isOptionSelected={(option) => {
            return vm.state.filters.assetType.some(
              (f) => f.value === option.value,
            );
          }}
          isSearchable={true}
          menuPlacement='auto'
        />
      </div>
      <div className='filter'>
        <label>Inspection Type:</label>
        <Select
          className='filter-select'
          options={vm.getFilterOptions('assetInspectionType')}
          value={vm.getFilterValue('assetInspectionType')}
          onChange={(option) => vm.setFilter('assetInspectionType', option)}
          isOptionSelected={(option) => {
            return vm.state.filters.assetInspectionType.some(
              (f) => f.value === option.value,
            );
          }}
          isSearchable={true}
          menuPlacement='auto'
        />
      </div>
      <div className='filter'>
        <Checkbox
          className='filter-checkbox'
          label='Missing Plans'
          checked={vm.state.filters.assetMissingPlans}
          onChange={(ev) =>
            vm.setBoolFilter('assetMissingPlans', ev.target.checked)
          }
        />
      </div>
      {(!!vm.state.filters.assetType.length ||
        !!vm.state.filters.assetStatus.length ||
        !!vm.state.filters.customAttribute.length ||
        !!vm.state.filters.companyAttribute.length ||
        !!vm.state.filters.assetInspectionType.length) && (
          <div className='selected-filters-container'>
            <label>Filters:</label>
            {vm.state.filters.assetType.map((filter) => (
              <StatusPill
                variant={STATUS_PILL_VARIANT.INFO}
                onClick={() => vm.setFilter('assetType', filter)}
              >
                {filter.label} ×
              </StatusPill>
            ))}
            {vm.state.filters.assetStatus.map((filter) => (
              <StatusPill
                variant={STATUS_PILL_VARIANT.INFO}
                onClick={() => vm.setFilter('assetStatus', filter)}
              >
                {filter.label} ×
              </StatusPill>
            ))}
            {vm.state.filters.customAttribute.map((filter) => (
              <StatusPill
                variant={STATUS_PILL_VARIANT.INFO}
                onClick={() => vm.setFilter('customAttribute', filter)}
              >
                <strong>{filter.groupLabel}:</strong> {filter.label} ×
              </StatusPill>
            ))}
            {vm.state.filters.companyAttribute.map((filter) => (
              <StatusPill
                variant={STATUS_PILL_VARIANT.INFO}
                onClick={() => vm.setFilter('companyAttribute', filter)}
              >
                <strong>{filter.groupLabel}:</strong> {filter.label} ×
              </StatusPill>
            ))}
            {vm.state.filters.assetInspectionType.map((filter) => (
              <StatusPill
                variant={STATUS_PILL_VARIANT.INFO}
                onClick={() => vm.setFilter('assetInspectionType', filter)}
              >
                {filter.label} ×
              </StatusPill>
            ))}
          </div>
        )}
    </>
  );
  const sourceFilters = (
    <>
      <div className='filter'>
        <label>Tags:</label>
        <Select
          className='filter-select'
          options={vm.getFilterOptions('sourceTag')}
          value={vm.getFilterValue('sourceTag')}
          onChange={(option) => vm.setFilter('sourceTag', option)}
          isOptionSelected={(option) => {
            return vm.state.filters.sourceTag.some(
              (f) => f.value === option.value,
            );
          }}
          isSearchable={true}
          menuPlacement='auto'
        />
      </div>
      <div className='filter'>
        <label>Country:</label>
        <Select
          className='filter-select'
          options={vm.getFilterOptions('sourceCountry')}
          value={vm.getFilterValue('sourceCountry')}
          onChange={(option) => vm.setFilter('sourceCountry', option)}
          isOptionSelected={(option) => {
            return vm.state.filters.sourceCountry.some(
              (f) => f.value === option.value,
            );
          }}
          isSearchable={true}
          menuPlacement='auto'
        />
      </div>
      <div className='filter'>
        <label>Source Type:</label>
        <Select
          className='filter-select'
          options={vm.getFilterOptions('sourceType')}
          value={vm.getFilterValue('sourceType')}
          onChange={(option) => vm.setFilter('sourceType', option)}
          isOptionSelected={(option) => {
            return vm.state.filters.sourceType.some(
              (f) => f.value === option.value,
            );
          }}
          isSearchable={true}
          menuPlacement='auto'
        />
      </div>
      <div className='filter'>
        <label>Inspection Type:</label>
        <Select
          className='filter-select'
          options={vm.getFilterOptions('sourceInspectionType')}
          value={vm.getFilterValue('sourceInspectionType')}
          onChange={(option) => vm.setFilter('sourceInspectionType', option)}
          isOptionSelected={(option) => {
            return vm.state.filters.sourceInspectionType.some(
              (f) => f.value === option.value,
            );
          }}
          isSearchable={true}
          menuPlacement='auto'
        />
      </div>
      <div className='filter'>
        <Checkbox
          className='filter-checkbox'
          label='Missing Plans'
          checked={vm.state.filters.sourceMissingPlans}
          onChange={(ev) =>
            vm.setBoolFilter('sourceMissingPlans', ev.target.checked)
          }
        />
      </div>
      {(!!vm.state.filters.sourceTag.length ||
        !!vm.state.filters.sourceType.length ||
        !!vm.state.filters.sourceCountry.length ||
        !!vm.state.filters.sourceInspectionType.length) && (
          <div className='selected-filters-container'>
            <label>Filters:</label>
            {vm.state.filters.sourceTag.map((filter) => (
              <StatusPill
                variant={STATUS_PILL_VARIANT.INFO}
                onClick={() => vm.setFilter('sourceTag', filter)}
              >
                {filter.label} ×
              </StatusPill>
            ))}
            {vm.state.filters.sourceType.map((filter) => (
              <StatusPill
                variant={STATUS_PILL_VARIANT.INFO}
                onClick={() => vm.setFilter('sourceType', filter)}
              >
                {filter.label} ×
              </StatusPill>
            ))}
            {vm.state.filters.sourceCountry.map((filter) => (
              <StatusPill
                variant={STATUS_PILL_VARIANT.INFO}
                onClick={() => vm.setFilter('sourceCountry', filter)}
              >
                {filter.label} ×
              </StatusPill>
            ))}
            {vm.state.filters.sourceInspectionType.map((filter) => (
              <StatusPill
                variant={STATUS_PILL_VARIANT.INFO}
                onClick={() => vm.setFilter('sourceInspectionType', filter)}
              >
                {filter.label} ×
              </StatusPill>
            ))}
          </div>
        )}
    </>
  );

  const linkableResourceFilters =
    vm.state.linkableResources.tab === 'assets' ? assetFilters : sourceFilters;

  if (isAutoBuildActive) {
    return (
      <div className='auto-build-progress'>
        {vm?.state?.autoBuildProgress?.totalCount > 0 ? (
          <>
            <StatusProgressPanel statusData={vm?.state?.autoBuildProgress} />
            <span className='description'>
              {!!vm.state.errors?.length && (
                <ErrorBag errors={vm.state.errors} />
              )}
            </span>
          </>
        ) : (
          <div className="status-processing-panel">
            <div className='progress-icon' />
            <span>Processing...</span>
            <span className='description'>
              {vm.state.errors?.length ? (
                <ErrorBag errors={vm.state.errors} />
              ) : (
                <>Latest auto build plan is being processed.</>
              )}
            </span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='auto-build-plan-form'>
      {hasResults && (
        <div className='last-auto-build-result'>
          <BulkResultsHeader
            totals={getStatusTotals(vm.state.autoBuildProgress?.response)}
          />
          <Button
            className='medium-button view-results'
            onClick={() => props.history.push('/inspection-plans/add/results')}
            data-testid='AutoBuildPlanForm.viewResults'
          >
            View results
          </Button>
        </div>
      )}
      <form noValidate>
        <div className='auto-build-description-container'>
          <p>
            For automatic plan building, the builder will match tags from
            question groups with the following:
          </p>
          <p>
            <span>
              For assets:{' '}
              <strong>asset attributes, custom fields, asset types</strong>
            </span>
            <br />
            <span>
              For sources: <strong>source type</strong>
            </span>
          </p>
        </div>
        <div className='form-row'>
          <Button
            type='button'
            className='medium-button'
            onClick={vm.userOpensResourcesModal}
            data-testid='AutoBuildPlanForm.selectButton'
          >
            Select assets / sources
          </Button>
          <p
            className={classNames('selected-resources-summary', {
              'is-invalid': vm.state.linkedResources.errors.length,
            })}
          >
            <span className='summary-item'>
              <PackageOutline />
              <strong>
                {vm.state.linkedResources.assets.totalSelected}
              </strong>{' '}
              assets selected
            </span>
            <span className='summary-item'>
              <NavigationOutline />
              <strong>
                {vm.state.linkedResources.sources.totalSelected}
              </strong>{' '}
              sources selected
            </span>
          </p>
          <ErrorBag errors={vm.state.linkedResources.errors} />
        </div>
        <ProtectedComponent allowed={OVERRIDE_PLANS_BULK_CREATION}>
          <div className='override-plans-container form-row'>
            <Checkbox
              label='Override existing plans (Administrator only)'
              checked={vm.state.overridePlans.value}
              onChange={vm.userTogglesOverridePlans}
              className='outline'
            />
            <p className='field-description'>
              If checked, the current plans attached to assets/sources will be
              replaced with new ones instead of not being created, unless they
              were edited by a user.
            </p>
          </div>
        </ProtectedComponent>
        <ProtectedComponent allowed={OVERRIDE_PLANS_BULK_CREATION}>
          <div className='override-plans-container form-row'>
            <Checkbox
              label='Override user-edited plans (Administrator only)'
              checked={
                vm.isOverrideUserPlansDisabled
                  ? false
                  : vm.state.overrideUserPlans.value
              }
              onChange={vm.userTogglesOverrideUserPlans}
              disabled={vm.isOverrideUserPlansDisabled}
              className='outline'
            />
            <p className='field-description'>
              If checked, the current plans will be replaced with new ones even
              if they were edited by a user.
            </p>
          </div>
        </ProtectedComponent>
        <div className='inspection-types-container form-row'>
          <h3>Inspection Types</h3>
          <div className='inspection-types-info-container form-row'>
            <p className='field-description'>
              For each inspection type, the builder will create a plan that has
              at least 1 question group match for it. If no question groups
              match, the plan will not be created.
            </p>

            <p className='field-description'>
              For more information on how AQL is calculated within Milspec,
              please refer to this{' '}
              <a target='_blank' href='/documents/mil_std_105e_aql_normal.pdf'>
                document
              </a>
              .
            </p>

            <p className='field-description'>
              Validity will skip the next inspections for that asset at that
              source for the chosen time period.
            </p>
          </div>
          <div className='form-row'>
            {(vm.state.bulkParams || []).map((param, index) => (
              <div className='auto-build-param' key={index}>
                <div className='inspection-type'>
                  <Select
                    label={renderformLabel('Inspection type', index)}
                    className='inspection-type'
                    placeholder=''
                    name={`inspection-type-${index}`}
                    isSearchable={true}
                    onChange={(e) => vm.userSetsInspectionType(e, index)}
                    errors={param.type.errors}
                    value={param.type.value}
                    options={vm.state.availableTypeOptions}
                    data-testid={`AutoBuildPlanForm.inspectionType${index}`}
                  />
                </div>
                <div className='aql-container'>
                  <div className='aql-level'>
                    <Select
                      label={renderformLabel('AQL Level', index)}
                      name={`aql-level-${index}`}
                      placeholder=''
                      onChange={(e) => vm.userSelectsAqlLevel(e, index)}
                      value={param.aqlLevel?.value}
                      isClearable
                      options={vm.state.planOptions.aqlLevel}
                      data-testid={`AutoBuildPlanForm.aqlLevel${index}`}
                      errors={param.aqlLevel.errors}
                    />
                  </div>
                  <div className='aql-major'>
                    <Select
                      label={renderformLabel('Major', index)}
                      name={`aql-major-${index}`}
                      placeholder=''
                      onChange={(e) => vm.userSelectsAqlMajor(e, index)}
                      value={param.aqlMajor?.value}
                      options={vm.state.planOptions.majorDefect}
                      data-testid={`AutoBuildPlanForm.majorDefect${index}`}
                      errors={param.aqlMajor.errors}
                    />
                  </div>
                  <div className='aql-minor'>
                    <Select
                      label={renderformLabel('Minor', index)}
                      name={`aql-minor-${index}`}
                      placeholder=''
                      onChange={(e) => vm.userSelectsAqlMinor(e, index)}
                      value={param.aqlMinor?.value}
                      options={vm.state.planOptions.minorDefect}
                      data-testid={`AutoBuildPlanForm.minorDefect${index}`}
                      errors={param.aqlMinor.errors}
                    />
                  </div>
                  <div className='aql-functional'>
                    <Select
                      label={renderformLabel('Functional', index)}
                      name={`aql-functional-${index}`}
                      placeholder=''
                      onChange={(e) => vm.userSelectsAqlFunctional(e, index)}
                      value={param.aqlFunctional?.value}
                      options={vm.state.planOptions.functionalDefect}
                      data-testid={`AutoBuildPlanForm.functionalDefect${index}`}
                      errors={param.aqlFunctional.errors}
                    />
                  </div>
                  <div className='defects-container'>
                    {!!renderformLabel('Critical defects allowed', index) && (
                      <p className='form-label'>
                        {renderformLabel('Critical defects allowed', index)}
                      </p>
                    )}
                    <div className='flex'>
                      <Input
                        name={`critical-defects-input-${index}`}
                        className={classNames('critical-defects-input', {
                          'is-invalid': param.criticalDefect.errors.length,
                        })}
                        type='text'
                        value={param.criticalDefect.value}
                        autoComplete='off'
                        onChange={(e) => vm.userTypesCriticalDefect(e, index)}
                        data-testid={`AutoBuildPlanForm.criticalDefectValue${index}`}
                      />
                      <Switch
                        offLabel='#'
                        onLabel='%'
                        name={`critical-defects-switch-${index}`}
                        className='critical-defects-switch'
                        onChange={() => vm.userChangesCriticalDefectRule(index)}
                        checked={vm.getCriticalDefectRuleValue(index)}
                        data-testid={`AutoBuildPlanForm.criticalDefectRule${index}`}
                      />
                    </div>
                    <ErrorBag
                      data-testid='AutoBuildPlanForm.criticalDefectErrors'
                      errors={[
                        ...param.criticalDefect.errors,
                        ...param.criticalDefectRule.errors,
                      ]}
                    />
                  </div>
                </div>
                <div className='validity-container'>
                  {!!renderformLabel('Validity', index) && (
                    <p className='form-label'>
                      {renderformLabel('Validity', index)}
                    </p>
                  )}
                  <div className='flex'>
                    <Input
                      name={`validity-${index}`}
                      type='text'
                      value={param.validity.value}
                      autoComplete='off'
                      onChange={(e) => vm.userTypesValidity(e, index)}
                      data-testid={`AutoBuildPlanForm.validityValue${index}`}
                    />
                    <Select
                      name={`validity-range-${index}`}
                      className='validity-range'
                      placeholder=''
                      onChange={(e) => vm.userSelectsValidityRange(e, index)}
                      value={param.validityRange.value}
                      options={vm.state.planOptions.validityRange}
                      data-testid='AutoBuildPlanForm.validityRange'
                    />
                  </div>
                  <ErrorBag
                    data-testid='AutoBuildPlanForm.validityErrors'
                    errors={[
                      ...param.validity.errors,
                      ...param.validityRange.errors,
                    ]}
                  />
                </div>
                {index > 0 && (
                  <div className='delete-param-container'>
                    <DeleteButton
                      onClick={() => vm.userRemovesBulkParam(index)}
                      data-testid={`AutoBuildPlanForm.delete${index}`}
                    />
                  </div>
                )}
              </div>
            ))}
            <Button
              type='button'
              className='medium-button add-inspection-type'
              onClick={vm.userAddsNewBulkParam}
              data-testid='AutoBuildPlanForm.addInspectionType'
            >
              Add inspection type
            </Button>
          </div>
        </div>
        <AddButton
          type='submit'
          disabled={vm.state.loading}
          onClick={vm.userSubmitsForm}
          data-testid='AutoBuildPlanForm.submit'
        >
          Build inspection plans
        </AddButton>
      </form>
      <LinkableResourcesModal
        isMultipleSelection
        className='auto-build-plan-form-linkable-resources-modal'
        data-testid='AutoBuildPlanForm.linkableResourcesModal'
        data={vm.getLinkableResourcesList()}
        selectedRows={vm.getLinkableResourcesSelected()}
        selectedPages={vm.getSelectedPages()}
        isAllSelected={vm.getIsAllSelected()}
        onSelectRow={vm.userTogglesSelectItem}
        onSelectPage={vm.userTogglesSelectPage}
        onSelectAll={vm.userTogglesSelectAll}
        onClearSelection={vm.userClearsSelection}
        isOpen={vm.state.linkableResources.isModalOpen}
        onRequestClose={vm.userCancelsResourcesModal}
        onSearchChange={vm.userSearchesResources}
        search={vm.state.linkableResources.search}
        setSortBy={vm.userSortsResources}
        setPage={vm.userSetsResourcesPage}
        page={vm.state.linkableResources.page}
        pageSize={vm.state.linkableResources.pageSize}
        sortBy={vm.state.linkableResources.sortBy}
        sortOrder={vm.state.linkableResources.sortOrder}
        onTabChange={vm.userChangesResourcesModalTab}
        tab={vm.state.linkableResources.tab}
        onSubmit={vm.userSelectsResources}
        filters={linkableResourceFilters}
      />
    </div>
  );
};

export default AutoBuildPlanForm;
